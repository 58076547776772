


















































import VueBase from '@/VueBase'
import { Component } from 'vue-property-decorator'
import { prmissionList, defaultExpandedKeys, noChildren } from './components/data'

@Component({ name: 'AddRole' })
export default class AddRole extends VueBase {
  private formData: any = {
    name: '',
    status: 1,
    permission: {
      routes: [],
      buttons: []
    },
  }
  private rules: any = {
    name: [{ required: true, message: '请输入角色名称', trigger: 'blur' }]
  }
  private treeProps = []
  private treeData: any = prmissionList
  private defaultExpandedKeys: any = defaultExpandedKeys
  private defaultCheckedKeys: any = ['Dashboard', 'license']
  private noChildren: any = noChildren

  handleCheckChange() {}

  async handleSubmit() {
    this.formData.permission.buttons = []
    this.formData.permission.routes = []
    if( !this.formData.name ) {
      this.$message.warning('请输入角色名称')
      return
    }
    let checked: any = [
      ...(this.$refs.tree as any)?.getCheckedNodes(),
      ...(this.$refs.tree as any)?.getHalfCheckedNodes()
    ]
    checked.forEach((ele: any) => {
      if(typeof ele.id === 'number') {
        let temp = {
          label: ele.label,
          id: ele.id
        }
        this.formData.permission.buttons.push(temp)
      } else if (ele.id) {
        this.formData.permission.routes.push(ele.id)
      }
    });

    let res: any = {}
    let msg: any = '创建'
    if(this.id) {
      msg = '编辑'
      res = await this.services.authority.editRole(this.id, this.formData)
    } else {
      res = await this.services.authority.addRole(this.formData)
    }
    if (res.status != 201) {
      this.$message.error(`角色${msg}失败，请重试`)
      return
    }
    this.$message.success(`角色${msg}成功`)
    await this.$router.go(-1)
  }

  goBack() {
    this.$router.go(-1)
  }

  private id: any = ''
  created() {
    this.id = this.$route.query.id
    if(this.id) {
      this.getDetail()
    }
    this.init()
  }
  init() {
    this.treeData[2].disabled = true
    this.treeData[2].children = this.treeData[2].children.map((item: any) => {
      item.children.map((ele: any) => {
        ele.disabled = true
        if((!this.id || !this.$route.query.preset) && (ele.id == 50 || ele.id == 51)) {
          ele.disabled = false
        }
        return ele
      })
      item.disabled = true
      return item
    })
  }
  async getDetail() {
    let { status, data } = await this.services.authority.getRoleById(this.id)
    if (status !== 201) return
    this.formData = data
    let rights = [
      ...data.permission.buttons,
      ...data.permission.routes
    ].filter((item: any) => typeof item.id === 'number' || this.noChildren.includes(item))
    rights = rights.map((item: any) => {
      if(item.id) return item.id
      return item
    })
    ;(this.$refs.tree as any)?.setCheckedKeys([...rights, ...this.defaultCheckedKeys])
    // console.log('rights', rights)
  }
}
