export const prmissionList = [
  {
    label: '系统模块配置',
    id: 'dashboard',
    isCollapse: true,
    children: [
      {
        label: '首页',
        id: 'Dashboard',
        disabled: true,
        children: []
      },
      {
        label: '部署指南',
        id: 'deployment',
        children: [
          // {
          //   label: '环境部署',
          //   id: 'environment',
          //   children: []
          // },
          {
            label: 'Agent 安装',
            id: 'deploy',
            children: []
          },
          // {
          //   label: '巡检排查',
          //   id: '',
          //   children: []
          // }
        ]
      },
      {
        label: '项目管理',
        id: 'project',
        children: [
          {
            label: '项目管理',
            id: 'team',
            children: [
              {
                label: '新增项目',
                id: 48
              },
              {
                label: '删除项目',
                id: 49
              },
              {
                label: '编辑项目',
                id: 50
              },
              {
                label: '查看详情',
                id: 51
              },
            ]
          },
          {
            label: '应用列表',
            id: 'projectManage',
            children: [
              {
                label: '新增应用',
                id: 1,
              },
              {
                label: '删除应用',
                id: 2,
              },
              {
                label: '编辑应用',
                id: 3
              },
              {
                label: '生成报告',
                id: 4
              }
            ]
          },
          {
            label: '应用漏洞',
            id: 'vulnList',
            children: [
              {
                label: '删除漏洞',
                id: 5
              },
              // {
              //   label: '关联漏洞',
              //   id: 6
              // },
              {
                label: '漏洞分享',
                id: 7
              },
              {
                label: '状态变更',
                id: 8
              },
              {
                label: '集成同步',
                id: 9
              },
              // {
              //   label: '下载调用链',
              //   id: 58
              // },
              {
                label: '请求重放',
                id: 59
              }
            ]
          },
          {
            label: '组件漏洞',
            id: 'scaList',
            children: [
              {
                label: '组件分享',
                id: 10
              },
            ]
          },
          {
            label: 'Agent 管理',
            id: 'agentManage',
            children: [
              {
                label: '启用',
                id: 11
              },
              {
                label: '暂停',
                id: 12
              },
              {
                label: '导出日志',
                id: 13
              },
              {
                label: '批量升级',
                id: 14
              },
              // {
              //   label: '主动验证',
              //   id: 15
              // },
              {
                label: '熔断配置',
                id: 16
              },
            ]
          },
          // {
          //   label: '黑盒漏洞',
          //   id: 'scanList',
          //   children: [{}]
          // },
        ]
      },
      {
        label: '集成管理',
        id: 'integrationManagement',
        children: [
          // {
          //   label: 'IDE 插件',
          //   id: 17
          // },
          {
            label: 'CI/CD 集成',
            id: 18
          },
          {
            label: '缺陷管理',
            id: 19
          },
          {
            label: '消息通知',
            id: 20
          },
          {
            label: '其他',
            id: 21
          },
        ]
      },
      {
        label: '策略管理',
        id: 'strategyBox',
        children: [
          {
            label: '检测策略',
            id: 'strategyManage',
            children: [
              {
                label: '新增策略',
                id: 22
              },
              {
                label: '编辑策略',
                id: 23
              },
              {
                label: '删除策略',
                id: 24
              },
              {
                label: '修改状态',
                id: 25
              },
            ]
          },
          {
            label: '策略模版',
            id: 'templateManage',
            children: [
              {
                label: '新增模版',
                id: 26
              },
              {
                label: '编辑模版',
                id: 27
              },
              {
                label: '删除模版',
                id: 28
              },
              {
                label: '修改状态',
                id: 29
              },
            ]
          },
          {
            label: '自定义规则',
            id: 'hookRule',
            children: [
              {
                label: '添加规则类型',
                id: 30
              },
              {
                label: '添加规则',
                id: 31
              },
              {
                label: '删除规则',
                id: 32
              },
              {
                label: '修改状态',
                id: 33
              },
              {
                label: '全部启用',
                id: 34
              },
              {
                label: '全部禁用',
                id: 35
              },
              {
                label: '全部删除',
                id: 36
              },
            ]
          },
          {
            label: '敏感信息配置',
            id: 'sensitiveManage',
            children: [
              {
                label: '新增规则',
                id: 37
              },
              {
                label: '编辑规则',
                id: 38
              },
              {
                label: '删除规则',
                id: 39
              },
              {
                label: '修改状态',
                id: 40
              },
            ]
          },
          // {
          //   label: '项目模版',
          //   id: 'projectTemplate',
          //   children: [
          //     {
          //       label: '新增配置',
          //       id: 41
          //     },
          //     {
          //       label: '编辑模版',
          //       id: 42
          //     },
          //     {
          //       label: '删除模版',
          //       id: 57
          //     },
          //   ]
          // },
          {
            label: '系统设置',
            id: 'systemSettings',
            children: [
              {
                label: '只读',
                id: 43
              },
              {
                label: '修改',
                id: 44
              },
            ]
          },
        ]
      },
      {
        label: '数据查询',
        id: 'search',
        children: []
      },
      {
        label: '报告中心',
        id: 'center',
        icon: 'iconbaogao',
        children: [
          {
            label: '应用报告',
            id: 'reportCenter',
          },
          {
            label: '分享记录',
            id: 'vulnSharing',
          },
        ]
      },
    ]
  },
  // {
  //   label: '关联图谱',
  //   id: 'links',
  //   children: []
  // },
  // {
  //   label: 'microview知识库',
  //   id: 'knowledge',
  //   children: []
  // },
  {
    label: '权限管理',
    id: 'authority',
    isCollapse: true,
    children: [
      {
        label: '角色管理',
        id: 'roleSetting',
        children: [
          {
            label: '新增角色',
            id: 45
          },
          {
            label: '删除角色',
            id: 46
          },
          {
            label: '编辑角色',
            id: 47
          },
        ]
      },
      // {
      //   label: '项目管理',
      //   id: 'team',
      //   children: [
      //     {
      //       label: '新增项目组',
      //       id: 48
      //     },
      //     {
      //       label: '删除项目组',
      //       id: 49
      //     },
      //     {
      //       label: '编辑项目组',
      //       id: 50
      //     },
      //     {
      //       label: '查看详情',
      //       id: 51
      //     },
      //   ]
      // },
      {
        label: '账号管理',
        id: 'account',
        children: [
          {
            label: '新增账号',
            id: 52
          },
          {
            label: '删除账号',
            id: 53
          },
          {
            label: '编辑账号',
            id: 54
          },
          {
            label: '查看详情',
            id: 55
          },
          {
            label: '开启MFA',
            id: 60
          },
        ]
      },
    ]
  },
  {
    label: '授权更新',
    id: 'license',
    children: [],
    disabled: true,
  },
  {
    label: 'Logo设置',
    id: 'changeLogo',
    children: []
  },
  {
    label: '操作日志',
    id: 'logManage',
    children: []
  },
  {
    label: '关于microview',
    id: 'about',
    children: []
  },
  {
    label: '消息通知',
    id: 56,
    children: []
  },
]

// 离谱交互
export let defaultExpandedKeys: any = ['deployment', 'project', 'projectManage', 'vulnList', 'scaList',
'agentManage', 'integrationManagement', 'strategyBox', 'center',
'strategyManage', 'templateManage', 'hookRule', 'sensitiveManage',
'projectTemplate', 'systemSettings', 'iconbaogao', 'roleSetting', 'team', 'account']

export let noChildren: any = ['links','knowledge','license','changeLogo','logManage','about',
'environment', 'deploy', 'scanList', 'search', 'reportCenter', 'vulnSharing']